/** @jsx jsx */
import { DUIText } from "@alch/ui";
import { memo, ReactElement } from "react";
import { Box, BoxProps, jsx } from "theme-ui";

import { User } from "../http/endpoints";

export interface LoggedInInfoProps extends BoxProps {
  user: User;
}

const LoggedInInfo = memo(function LoggedInInfo({
  user,
  ...boxProps
}: LoggedInInfoProps): ReactElement {
  const { firstName, lastName, email } = user;

  return (
    <Box {...boxProps}>
      <DUIText size="md">You are currently logged in as</DUIText>
      <DUIText my={3} sx={{ fontWeight: "500" }} size="lg">
        {firstName} {lastName} ({email})
      </DUIText>
    </Box>
  );
});
export default LoggedInInfo;
