/** @jsx jsx */
import { DUIButton, DUIText } from "@alch/ui";
import useIdentifyUser from "@hooks/useIdentifyUser";
import {
  Fragment,
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { jsx } from "theme-ui";
import { StringParam, useQueryParam } from "use-query-params";

import ErrorView from "../components/ErrorView";
import LoggedInInfo from "../components/LoggedInInfo";
import MasterLayout from "../components/MasterLayout";
import OrSeparator from "../components/OrSeparator";
import QueryLink from "../components/QueryLink";
import Section from "../components/Section";
import { fullWidthSx } from "../components/styles";
import {
  confirmInviteFromSession,
  getInviteInfo,
  getUser,
  InviteInfo,
  LoginResult,
  User,
} from "../http/endpoints";
import { Loading, useWaitForLoad } from "../util/loading";

const InvitePage = memo(function InvitePage(): ReactElement {
  const [inviteToken] = useQueryParam("inviteToken", StringParam);
  const [user, setUser] = useState(Loading.inProgress<User | undefined>());
  const [invite, setInvite] = useState(Loading.inProgress<InviteInfo>());
  const [result, setResult] = useState(Loading.unloaded<LoginResult>());
  const waitForLoad = useWaitForLoad();
  const identifyUser = useIdentifyUser();

  const handleUseCurrentUser = useCallback(() => {
    if (!inviteToken) {
      console.error("Invite token not set.");
      return;
    }
    waitForLoad(confirmInviteFromSession(inviteToken), setResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy (turned on exhaustive-deps for new code)
  }, [inviteToken, user.value?.id]);

  useEffect(() => {
    if (!inviteToken) {
      window.location.replace("/");
      return;
    }
    waitForLoad(getUser(), (result) => {
      if (!result.error) {
        setUser(result);
      }
    });
    waitForLoad(getInviteInfo(inviteToken), setInvite);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Legacy (turned on exhaustive-deps for new code)
  }, []);

  useEffect(() => {
    const redirectTo = result.value?.redirectTo;
    if (redirectTo) {
      identifyUser(() => {
        window.location.href = redirectTo;
      });
    }
  }, [identifyUser, result.value?.redirectTo]);

  return <MasterLayout title="Confirm Invite">{renderContents()}</MasterLayout>;

  function renderContents(): ReactElement {
    if (user.error) {
      return (
        <ErrorView
          title="Failed to load current user"
          message={user.error.message ?? "An unknown error occurred."}
        />
      );
    }
    if (invite.error) {
      return (
        <ErrorView
          title="Failed to load invite"
          message={invite.error.message ?? "An unknown error occurred."}
        />
      );
    }
    return (
      <Fragment>
        {renderHeaderSection()}
        {user.value ? renderAuthedSection(user.value) : renderUnauthedSection()}
      </Fragment>
    );
  }

  function renderHeaderSection(): ReactElement {
    const serviceName = invite.value?.serviceName;
    return (
      <Section centered={true}>
        <DUIText size="2xl">Confirm invite</DUIText>
        <DUIText
          size="md"
          mt={2}
          sx={{
            maxWidth: "300px",
            color: "gray.4",
            visibility: serviceName ? undefined : "hidden",
          }}
        >
          Confirm your invite for <b>{serviceName}</b>.
        </DUIText>
      </Section>
    );
  }

  function renderUnauthedSection(): ReactElement {
    return (
      <Section>
        <QueryLink to="/">
          <DUIButton sx={fullWidthSx} size="lg">
            Sign in to an existing account
          </DUIButton>
        </QueryLink>
        <OrSeparator my={4} />
        <QueryLink to="/signup">
          <DUIButton sx={fullWidthSx} size="lg">
            Create a new account
          </DUIButton>
        </QueryLink>
      </Section>
    );
  }

  function renderAuthedSection(loadedUser: User): ReactElement {
    return (
      <Fragment>
        <Section centered={true}>
          <LoggedInInfo user={loadedUser} />
          <DUIText mt={3} size="md">
            Use this account to accept your invite?
          </DUIText>
          <DUIButton
            mt={5}
            sx={fullWidthSx}
            size="lg"
            disabled={result.isLoading}
            onClick={handleUseCurrentUser}
          >
            Accept invite
          </DUIButton>
          {result.error && (
            <DUIText mt={3} sx={{ textAlign: "center", color: "danger.core" }}>
              {result.error.message ?? "An unknown error occurred."}
            </DUIText>
          )}
        </Section>
      </Fragment>
    );
  }
});
export default InvitePage;
