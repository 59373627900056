export function urlWithQuery(
  url: string,
  queryParams: Record<string, string | null | undefined>,
): string {
  const queryParts: string[] = [];
  for (const key in queryParams) {
    const value = queryParams[key];
    if (value) {
      queryParts.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      );
    }
  }
  return queryParts.length === 0 ? url : `${url}?${queryParts.join("&")}`;
}
