enum HeapPrefix {
  User = "user_",
}

interface IHeapProperties {
  [key: string]: string | number | boolean | undefined;
}

interface IHeap {
  track: (event: string, properties?: IHeapProperties) => void;
  identify: (identity: string, properties?: IHeapProperties) => void;
  addUserProperties: (properties: IHeapProperties) => void;
  addEventProperties: (properties: IHeapProperties) => void;
}

declare global {
  interface Window {
    heap: IHeap;
  }
}

export class Heap {
  private static get instance(): IHeap | undefined {
    if (window.heap === undefined) {
      console.warn("Heap has not been initialized.");
      return undefined;
    }

    return window.heap;
  }

  static identify(userId: string, properties: IHeapProperties = {}) {
    this.instance?.identify(HeapPrefix.User + userId);
    this.instance?.addUserProperties(properties);
  }

  static addUserProperties(properties: IHeapProperties) {
    this.instance?.addUserProperties(properties);
  }

  static addEventProperties(properties: IHeapProperties) {
    this.instance?.addEventProperties(properties);
  }

  static track(event: string, properties?: IHeapProperties) {
    this.instance?.track(event, properties);
  }
}
