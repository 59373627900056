import { useCallback } from "react";

import { Heap } from "../analytics/heap";
import { getUser } from "../http/endpoints";

/**
 * Hook that returns a function used to identify the current user in LaunchDarkly and Heap
 *
 * @returns identifyUser(onComplete) - function to identify the user
 */
const useIdentifyUser = () => {
  const identifyUser = useCallback(async (onComplete: () => void) => {
    try {
      const user = await getUser();
      if (!user) return;

      Heap.identify(user.id);
    } finally {
      onComplete();
    }
  }, []);

  return identifyUser;
};
export default useIdentifyUser;
