/** @jsx jsx */
import { DUIText } from "@alch/ui";
import { memo, ReactElement } from "react";
import { Box, BoxProps, Flex, jsx } from "theme-ui";

export type OrSeparatorProps = BoxProps;

const OrSeparator = memo(function OrSeparator(
  boxProps: OrSeparatorProps,
): ReactElement {
  return (
    <Flex {...boxProps} sx={{ alignItems: "center" }}>
      <Box
        sx={{
          flex: "1 1 0",
          borderBottom: "1px solid",
          borderBottomColor: "gray.2",
        }}
      />
      <DUIText mx={3} sx={{ flex: "0 0 auto", color: "gray.2" }} size="md">
        or
      </DUIText>
      <Box
        sx={{
          flex: "1 1 0",
          borderBottom: "1px solid",
          borderBottomColor: "gray.2",
        }}
      />
    </Flex>
  );
});
export default OrSeparator;
