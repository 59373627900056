/** @jsx jsx */
import { Link } from "gatsby";
import { ComponentProps, memo, ReactElement } from "react";
import { jsx } from "theme-ui";
import { StringParam, useQueryParams } from "use-query-params";

import { urlWithQuery } from "../util/queryParams";

export type QueryLinkProps = ComponentProps<typeof Link>;

/**
 * Like a Gatsby `Link`, except that it preserves the query params used by this
 * application.
 */
const QueryLink = memo(function QueryLink({
  to,
  ref: _,
  ...linkProps
}: QueryLinkProps): ReactElement {
  const [{ redirectUrl, inviteToken, initialEmail, referral }] = useQueryParams(
    {
      redirectUrl: StringParam,
      inviteToken: StringParam,
      initialEmail: StringParam,
      referral: StringParam,
    },
  );

  return (
    <Link
      to={urlWithQuery(to, {
        redirectUrl,
        inviteToken,
        initialEmail,
        referral,
      })}
      {...linkProps}
    />
  );
});
export default QueryLink;
